import React from "react"
import { graphql } from "gatsby"

import Seo from "components/Seo"
import { Banner } from "components/Banner"
import Magazine from "components/Magazine"
import Popup from "components/Popup"

export default function TemplateMagazine({ data, pageContext }) {
  const {
    dataMagazine,
    dataMagazineArticles,
    globals,
    allArticles,
  } = data?.craft
  const {
    seoTitle,
    seoDescription,
    seoImage,
    seoCanonicalTag,
  } = dataMagazine?.[0]

  const [curpage] = pageContext.alternateEntries

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        canonical={seoCanonicalTag}
        image={seoImage}
        defaults={data?.craft?.globals?.seo}
        pageContext={pageContext}
      />
      <Magazine
        allArticles={allArticles}
        dataCta={globals.dataCta}
        dataMagazine={dataMagazine}
        dataMagazineArticles={dataMagazineArticles}
        pageContext={pageContext}
      />
      <Banner curPageTitle={curpage.title} pageContext={pageContext} />
      <Popup pageContext={pageContext} />
    </>
  )
}

export const query = graphql`
  query TemplateMagazine($siteId: Int!) {
    craft {
      # Globals
      globals(siteId: $siteId) {
        ...GlobalNavigationSocialQuery
        ...GlobalCtaSignUpQuery
        ...GlobalFooterSectionQuery
        ...GlobalCookieInfoQuery
        ...GlobalCtaReadMoreQuery
        ...GlobalSeoQuery
        ...GlobalBannerQuery
      }

      # NavigationHeader
      navigationHeaderItems: entries(
        section: navigationHeader
        siteId: $siteId
      ) {
        ...NavigationHeaderQuery
      }

      # NavigationFooter
      navigationFooterItems: entries(
        section: navigationFooter
        siteId: $siteId
      ) {
        ...NavigationFooterQuery
      }

      # Magazine
      dataMagazine: entries(section: magazine, siteId: $siteId) {
        __typename
        ... on Craft_Magazine {
          title
          fullUri
          seoTitle
          seoDescription
          seoImage {
            optimizedUrl: url(crop: { width: 1080, quality: 90 })
          }
          magazineHeroBackground {
            id
            ...CraftImageFluid
          }
          magazineSlider {
            id
          }
          magazineButtonMore {
            url
            text
          }
          magazineHeroHeadlineVisual
          magazineHeroHeadline
          magazineHeroNavigation {
            id
            magazinHeroItemTitle
            magazineHeroItemLink {
              id
              uri
            }
          }
        }
      }

      # MagazineArticles
      dataMagazineArticles: entries(
        section: magazineArticle
        siteId: $siteId
        limit: 20
      ) {
        __typename
        ... on Craft_MagazineArticleMagazineEntry {
          id
          title
          fullUri
          postDate
          language
          magazineCat {
            id
            title
            uri
          }
          magazineTeaserText
          magazineArticleContent {
            __typename
            ... on Craft_MagazineArticleContentMagazineArticleText {
              articleText {
                totalPages
                content
              }
            }
          }
          magazineTeaserImage {
            ...CraftImageFluid
          }
          articleHeroImage {
            ...CraftImageFluid
          }
        }
      }
      allArticles: entries(section: magazineArticle) {
        ... on Craft_MagazineArticleMagazineEntry {
          title
          fullUri
        }
      }
    }
  }
`
